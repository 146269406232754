<template>
  <div class="add-project-phase">
    <fd-form class="modal-content-wrapper" @submit="submitForm">
      <!-- ============================ Body ============================= -->
      <modal-body class="p-2">
        <h4 class="mt-2 mb-3">Add Phase</h4>

        <fd-input
          v-model="form.phase"
          class="col-12 mb-2"
          label="Name"
          name="projectPhaseName"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>

        <!-- Assign Bank Account -->
        <fd-form-section title="Assign Bank Account" class="mt-4 mxn-1 px-1">
          <assign-bank-accounts
            v-model="form.developerBankAccounts"
            :data="bankAccountsData"
            :pagination="bankAccountsPagination"
            class="col-12"
            :validators="[
              (val) =>
                validator.required(val, {
                  err: 'Please select at least a bank account'
                })
            ]"
          ></assign-bank-accounts>
        </fd-form-section>
      </modal-body>
      <!-- ============================ Footer ============================= -->
      <modal-footer>
        <div class="row justify-content-end p-2">
          <button type="button" class="btn" @click="$emit('cancel')">
            Cancel
          </button>
          <button class="btn main ml-1">Create</button>
        </div>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import ProjectPhaseModel from "@/modules/Project/models/ProjectPhaseModel";
import DeveloperBankAccountAPI from "@/modules/Project/api/developerBankAccount";
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";

export default {
  components: {
    AssignBankAccounts: () =>
      import(
        "@/components/GlobalComponents/Shared/Developer/AssignBankAccounts"
      )
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      required: true
    },
    developerId: {
      required: true
    }
  },
  data: function () {
    return {
      bankAccAPI: new DeveloperBankAccountAPI(this.type),

      form: {
        phase: "",
        project: "",
        developerBankAccounts: []
      },

      bankAccountsData: [],
      bankAccountsPagination: {},

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        this.form.project = this.projectId;
        await this.getDeveloperBankAccounts();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },

    async getDeveloperBankAccounts() {
      try {
        let data = await this.bankAccAPI.getBankAccounts(this.developerId, {
          queries: {},
          page: 1,
          perPage: 30
        });

        this.bankAccountsData = this._.cloneDeep(data.data);
        this.bankAccountsPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to get developer's bank accounts. Please try again later."
        });
      }
    },
    submitForm() {
      this.$emit("submit", ProjectPhaseModel.postPayload(this.form));
    }
  }
};
</script>

<style lang="scss">
.add-project-phase {
  min-width: 500px;
}
</style>
